import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Link from 'components/ui/link';

import logo from 'img/companyLogo/user.jpg';

const Content = () => {
  return (
    <div>
      <SubTitle>What is User.com?</SubTitle>
      <p>
        User.com is a suite of tools for marketing, sales, management, and customer support teams.
        Integrating LiveSession with User.com will help you understand your customers better.
      </p>
      <SubTitle className="mt-5">How does it work?</SubTitle>
      <p>
        In every new conversation, you’ll see a label called <i>sessionURL</i>. It contains a link
        that will redirect you straight to the session recording.
      </p>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <span>To install integration, add the code below before your </span>{' '}
      <Code inline>{'</body>'}</Code> <span> closing tag:</span>
      <Code margin="1rem 0" copy lang="js">
        {`<script>
__ls("getSessionURL", function (url, isNewSession) {
  userengage("event.sessionURL", { sessionURL: url });
});
</script>
`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        For this integration to work, you need to have both User.com and LiveSession installed on
        your website. If you haven’t installed our tracking code yet, check{' '}
        <Link href="/help/how-to-install-livesession-script/">this article</Link>.
      </div>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and User.com integration',
  canonical: '/help/user-integration/',
  metaDescription: 'Go to session replay directly from User converstion.',
  logo,
};

const Wrapped = HelpArticle(Content);
export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
